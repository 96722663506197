/*
 * Newsletter.ts (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Newsletter.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { BASE_API_URL } from '../config';
import { createLogApi } from './Log';
import {
  ICustomLicenseList,
  INewsletter,
  INewsletterAnalytics,
  INewsletterBlacklist,
  INewsletterCustomList
} from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { mapFilterFields, mapSortingFields } from '../Utils/Pagination';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * @class NewsletterAPI
 */
export class NewsletterAPI {
  /**
   * Service to create or update newsletter
   * @param newsletter Newsletter data
   * @returns Promise
   */
  static createOrUpdate = async (
    newsletter: INewsletter
  ): Promise<IAPIEntityResponse<INewsletter>> => {
    const url: string = `/newsletter`;

    if (newsletter.newsletterUUID) {
      return httpClient.post<INewsletter>(`${url}/${newsletter.newsletterUUID}/update`, newsletter);
    }
    return httpClient.post<INewsletter>(`${url}/create`, newsletter);
  };

  /**
   * Service to fetch all newsletters with pagination
   * @param skip Pagination parameter
   * @param limit Pagination parameter
   * @param sort Pagination parameter
   * @param sortOrder Pagination parameter
   * @param searchTerm Search term
   * @returns newsletter with the requested pagination options
   */
  static getAllNewsletter = async (
    skip = 0,
    limit = defaultTableLimit,
    sort = 'created',
    sortOrder = 'DESC',
    searchTerm: string,
    filter = {}
  ): Promise<IAPIEntityResponse<INewsletter>> => {
    const url: string = `/newsletters/all`;
    sort = sort || 'updated';
    const mappedSort: string = mapSortingFields(
      sort,
      sortOrder
    ); /**< To map sortFields and sortOrder.*/

    const mappedFilter: string = mapFilterFields(filter, [
      'customLicenseList'
    ]); /** Remap operator of customLicenseList filter as 'IN' */

    return httpClient.get<INewsletter>(
      `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  };

  /**
   * get newsletter by id.
   * @param newsletterUUID newsletterUUID.
   * @returns Promise<HttpResponse<INewsletter>>
   */
  static getNewsletterByID = (newsletterUUID: string): Promise<IAPIEntityResponse<INewsletter>> => {
    return httpClient.get<INewsletter>(`/newsletter/${newsletterUUID}`);
  };

  /**
   * Service to delete newsletter(s)
   * @param payload NewsletterUUIDs
   * @returns Promise
   */
  static deleteNewsletter = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
    return httpClient.post('/newsletters/delete', { newsletterUUIDs: payload });
  };

  /**
   * Service to copy newsletter(s)
   * @param payload NewsletterUUIDs
   * @returns Promise
   */
  static copyNewsletter = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
    return httpClient.post('/newsletters/copy', { newsletterUUIDs: payload });
  };

  /**
   * Service to get custom licenses filter
   * @returns Promise
   */
  static customLicensesFilter = (): Promise<IAPIEntityResponse<ICustomLicenseList[]>> => {
    return httpClient.get<ICustomLicenseList[]>('/newsletter/customLicensesFilter');
  };

  /**
   * Send newsletter.
   * @param newsletterUUID newsletterUUID.
   */
  static sendNewsletter = (newsletterUUID: string): Promise<IAPIEntityResponse<void>> => {
    return httpClient.get(`/newsletter/sendNewsletter/${newsletterUUID}`);
  };

  /**
   * Service to create or update newsletter custom list
   * @param customList Newsletter customList data
   * @returns Promise
   */
  static createOrUpdateNewsletterCustomList = async (
    customList: INewsletterCustomList
  ): Promise<IAPIEntityResponse<INewsletterCustomList>> => {
    const url: string = `/newsletter/customList`;

    if (customList.customListUUID) {
      return httpClient.post<INewsletterCustomList>(
        `${url}/${customList.customListUUID}/update`,
        customList
      );
    }
    return httpClient.post<INewsletterCustomList>(`${url}/create`, customList);
  };

  /**
   * Service to fetch all custom list with pagination
   * @param skip Pagination parameter
   * @param limit Pagination parameter
   * @param sort Pagination parameter
   * @param sortOrder Pagination parameter
   * @param searchTerm Search term
   * @returns customlists with the requested pagination options
   */
  static getAllNewsletterCustomLists = async (
    skip = 0,
    limit = defaultTableLimit,
    sort = 'created',
    sortOrder = 'DESC',
    searchTerm: string
  ): Promise<IAPIEntityResponse<INewsletterCustomList>> => {
    const url: string = `/newsletter/customList/all`;
    sort = sort || 'updated';
    const mappedSort: string = mapSortingFields(
      sort,
      sortOrder
    ); /**< To map sortFields and sortOrder.*/

    return httpClient.get<INewsletterCustomList>(
      `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  };

  /**
   * Service to delete newsletter customList(s)
   * @param payload CustomListUUIDs
   * @returns Promise
   */
  static deleteNewsletterCustomList = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
    return httpClient.post('/newsletter/customList/delete', { customListUUIDs: payload });
  };

  /**
   * Service to copy newsletter custom list(s)
   * @param payload CustomListUUIDs
   * @returns Promise
   */
  static copyNewsletterCustomList = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
    return httpClient.post('/newsletter/customList/copy', { customListUUIDs: payload });
  };

  /**
   * Send newsletter preview to loggedin user.
   * @param newsletterUUID newsletterUUID.
   */
  static sendNewsletterPreview = (newsletterUUID: string): Promise<IAPIEntityResponse<void>> => {
    return httpClient.get(`/newsletter/sendNewsletter/preview/${newsletterUUID}`);
  };

  /**
   * Service to fetch newletter analytics using ID with pagination
   * @param newsletterID newsletterID
   * @param skip Pagination parameter
   * @param limit Pagination parameter
   * @param sort Pagination parameter
   * @param sortOrder Pagination parameter
   * @param searchTerm Search term
   * @returns customlists with the requested pagination options
   */
  static getNewsletterAnalytics = async (
    newsletterID: number,
    skip = 0,
    limit = defaultTableLimit,
    sort = 'sentDate',
    sortOrder = 'ASC',
    searchTerm: string
  ): Promise<IAPIEntityResponse<INewsletterAnalytics>> => {
    const url: string = `/newsletter/${newsletterID}/analytics`;
    const mappedSort: string = mapSortingFields(
      sort,
      sortOrder
    ); /**< To map sortFields and sortOrder.*/

    return httpClient.get<INewsletterAnalytics>(
      `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  };

  /**
   * Unsubscribe newsletter.
   * @param userUUID userUUID.
   */
  static unsubscribeNewsletter = (userUUID: string): Promise<IAPIEntityResponse<void>> => {
    return httpClient.get(`/newsletter/unsubscribe/${userUUID}`);
  };

  /**
   * Service to fetch newletter blacklist with pagination
   * @param skip Pagination parameter
   * @param limit Pagination parameter
   * @param sort Pagination parameter
   * @param sortOrder Pagination parameter
   * @param searchTerm Search term
   * @returns blacklist with the requested pagination options
   */
  static getNewsletterBlacklist = async (
    skip = 0,
    limit = defaultTableLimit,
    sort = 'unsubscribeDate',
    sortOrder = 'ASC',
    searchTerm: string
  ): Promise<IAPIEntityResponse<INewsletterBlacklist>> => {
    const url: string = `/newsletter/blacklist/all`;
    const mappedSort: string = mapSortingFields(
      sort,
      sortOrder
    ); /**< To map sortFields and sortOrder.*/

    return httpClient.get<INewsletterBlacklist>(
      `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  };
}
