
/**
 * Newsletter.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Newsletter.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { NewsletterLicenseType, NewsletterSendStatus } from "../../enum/license/Newsletter";
import { ILicense } from "./license";

/**
 * @interface INewsletter
 */
export interface INewsletter {
  id?: number; /**< Newsletter Id */
  newsletterUUID?: string; /**< Newsletter UUID */
  title?: string; /**< Newsletter title */
  description?: string; /**< Newsletter Description */
  newsletter?: string; /**< Newsletter */
  licenseType?: NewsletterLicenseType; /**< License type. */
  sendStatus?: NewsletterSendStatus; /** Newsletter send status */
  isSent?: boolean; /**< Sent status of newsletter */
  sentDate?: Date; /**< Timestamp of newsletter sent */
  customLicenses?: ILicense[]; /**< Custom License */
  customLicenseList?: INewsletterCustomList; /**< Custom list*/
  sentCount?: number; /**< Number of sent */
  openedCount?: number; /**< Number of opened */
  totalLicensesCount?: number; /**< Number of licenses count */
  pendingUsersCount?: number; /**< Number of pending users count */
  createdBy?: string; /**< CreatedBy*/
  updatedBy?: string; /**< UpdatedBy */
  created?: Date; /**< Timestamp of creation */
  updated?: Date; /**< Timestamp of updation */
  deleted?: Date; /**< Timestamp of deletion */
}

/**
 * @interface ICustomLicenseList
 */
export interface ICustomLicenseList {
  type: string; /**< License type */
  licenses: ILicense[]; /**< Licenses */
}

/**
 * @interface INewsletterPlaceholder
 */
export interface INewsletterPlaceholder {
  placeholderKey: string; /**< Placeholder key */
  placeholderValue: string; /**< Placeholder value */
}

/**
 * newsletterPlaceholder
 */
export const NewsletterPlaceholderList: INewsletterPlaceholder[] = [
  {
    placeholderKey: 'username',
    placeholderValue: 'John'
  },
  {
    placeholderKey: 'firstName',
    placeholderValue: 'John'
  },
  {
    placeholderKey: 'lastName',
    placeholderValue: 'Doe'
  },
  {
    placeholderKey: 'fullName',
    placeholderValue: 'John Doe'
  },
  {
    placeholderKey: 'email',
    placeholderValue: 'example@instalod.com'
  },
  { 
    placeholderKey: 'projectName', 
    placeholderValue: 'Example Project' 
  },
  { 
    placeholderKey: 'licenseEndDate', 
    placeholderValue: '28-01-2024' 
  },
  { 
    placeholderKey: 'licenseStartDate', 
    placeholderValue: '28-01-2023' 
  },
  { 
    placeholderKey: 'applicationName', 
    placeholderValue: 'Abstract License' 
  },
  { 
    placeholderKey: 'applicationDescription', 
    placeholderValue: 'Abstract License' 
  },
  { 
    placeholderKey: 'applicationLogoUrl', 
    placeholderValue: 'https://cloud.InstaLOD.io/img/icon-footer-email.png' 
  },
];

/**
 * @interface INewsletterCustomList
 */
export interface INewsletterCustomList {
  id?: number; /**< custom list Id */
  customListUUID?: string; /**< Custom list UUID */
  customListName?: string; /**< Custom list name */
  customLicenses?: ILicense[]; /**< Custom Licenses */
  customLicensesCount?: number; /**< Custom Licenses count */
  created?: Date; /**< Timestamp of creation */
  updated?: Date; /**< Timestamp of updation */
  deleted?: Date; /**< Timestamp of deletion */
}

/**
 * @interface INewsletterLicense
 */
export interface INewsletterLicense extends ILicense {
  placeholders?: Record<string, string>; /**< Placeholders */
}

/**
 * @interface INewsletterAnalytics
 */
export interface INewsletterAnalytics {
  newsletterID?: number; /**< NewsletterID */
  licenseID?: number; /**< LicenseID */
  userUUID?: string; /**< UserUUID */
  username?: string; /**< Username */
  email?: string; /**< User email */
  sendStatus?: NewsletterSendStatus; /** Newsletter send status */
  sentDate?: Date; /**< Timestamp of newsletter sent */
  isOpen?: boolean; /**< Open status of newsletter */
  openedDate?: Date; /**< Timestamp of newsletter open */
}

export interface INewsletterBlacklist {
  id?: number; /**< Newsletter blacklist Id */
  newsletterBlacklistUUID?: string; /**< Newsletter backlist UUID */
  userUUID: string; /**< UserUUID */
  username: string; /**< Username */
  email: string; /**< User email */
  unsubscribeDate: Date; /**< Timestamp of newsletter unsubscribe */
}